<template>
  <b-card title="RMS Report generator">
    <b-form
      inline
      class="mb-2"
    >
      <b-form-select
        v-model="selectedTenantId"
        :options="orderedTenants"
        text-field="name"
        value-field="id"
        size="sm"
      />

      <b-form-group
        class="ml-1"
        label="Time range"
        label-sr-only
      >
        <date-range-picker
          v-model="timeRange"
          :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last90Days', 'last180Days', 'last365Days']"
          :max-selectable-days="365"
        />
      </b-form-group>

      <b-button
        class="ml-1"
        size="sm"
        variant="primary"
        :disabled="!(selectedTenantId && getSelectedAssetIds().length > 0) || reportLoading"
        @click="generate"
      >
        <template v-if="reportLoading">
          <feather-icon icon="LoaderIcon" />
          Loading...
        </template>
        <template v-else>
          Generate
        </template>
      </b-button>

      <b-form-group>
        <b-form-checkbox
          v-model="includeSystemsWithoutProActive"
          class="ml-1"
        > Allow Systems without ProActive service
        </b-form-checkbox>
      </b-form-group>

    </b-form>

    <b-overlay :show="assetsLoading">
      <b-alert
        :show="hasAssetsWithNoProActiveService"
        variant="info"
      >
        You can only select assets with ProActive service. All other assets are greyed out.
      </b-alert>

      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>System</th>
              <th>Organization unit</th>
              <th>Product family</th>
              <th>Model</th>
              <th>Account</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="asset in orderedAssets"
              :key="asset.id"
            >
              <td>
                <b-form-checkbox
                  v-model="asset.isSelected"
                  :disabled="!includeSystemsWithoutProActive && asset.hasProActiveService === false"
                  class="ml-1"
                > {{ getAssetDisplayName(asset) }}
                </b-form-checkbox>
              </td>
              <td>{{ (asset.organizationUnit || {}).displayName }}</td>
              <td>{{ asset.productFamily }}</td>
              <td>{{ asset.model }}</td>
              <td>{{ (asset.account || {}).name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>

import {
  BCard, BFormSelect, BOverlay, BFormCheckbox, BAlert, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import TenantService from '@/service/tenant.service'
import AssetService from '@/service/asset.service'
import RmsReportingService from '@/service/rmsReporting.service'
import moment from '@/libs/moment'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'

export default {
  components: {
    BCard, BFormSelect, BOverlay, BFormCheckbox, BAlert, BForm, DateRangePicker, BFormGroup, BButton,
  },
  data() {
    return {
      tenants: [],
      assetsLoading: false,
      selectedTenantId: null,
      assets: [],
      reportLoading: false,
      includeSystemsWithoutProActive: false,
      timeRange: {
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: new Date(),
      },
    }
  },
  computed: {
    orderedTenants() {
      return this.tenants
        .concat()
        .sort((a, b) => (a.name > b.name ? -1 : 1))
    },
    hasAssetsWithNoProActiveService() {
      return this.assets.some(x => x.hasProActiveService === false)
    },
    orderedAssets() {
      return this.assets
        .concat()
        .sort((a, b) => (a.hasProActiveService > b.hasProActiveService ? -1 : 1))
    },
  },
  watch: {
    selectedTenantId() {
      this.loadAssets()
    },
  },
  mounted() {
    this.loadTenants()
  },
  methods: {
    getSelectedAssetIds() {
      return this.assets
        .filter(x => x.isSelected === true)
        .map(x => x.id)
    },
    getAssetDisplayName(asset) {
      if (asset.hostname) {
        return asset.hostname
      }

      return asset.systemNumber
    },
    generate() {
      this.reportLoading = true

      RmsReportingService.generateAndGetAsync({
        tenantId: this.selectedTenantId,
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        includeAssetIds: this.getSelectedAssetIds(),
      }).then(response => {
        const disposition = response.request.getResponseHeader('Content-Disposition')
        let filename = 'download.docx'
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()// you need to remove that elelment which is created before.
      })
        .finally(() => {
          this.reportLoading = false
        })
    },
    loadTenants() {
      TenantService.getAllListAsync()
        .then(result => {
          this.tenants = result
        })
    },
    loadAssets() {
      this.assets = []
      if (this.selectedTenantId) {
        this.assetsLoading = true
        AssetService.getAllListAsync(null, { impersonateAsTenant: this.selectedTenantId })
          .then(result => {
            this.assets = result

            /* Pre-Select all assets  */
            this.assets.forEach(item => {
              if (item.hasProActiveService === true && (item.asupSystemId || item.hasAnyCheckupLastPeriod)) {
              // eslint-disable-next-line no-param-reassign
                item.isSelected = true
              }
            })
          })
          .finally(() => {
            this.assetsLoading = false
          })
      }
    },
  },
}

</script>
