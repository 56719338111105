// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {

  async generateAndGetAsync(data, config) {
    const result = await axios.post(
      '/core/reporting/rms/generate',
      data,
      buildAxiosRequestConfig(
        config,
        requestConfig => {
          // eslint-disable-next-line no-param-reassign
          requestConfig.responseType = 'blob'
        },
      ),
    )

    return result
  },
}
